import React from "react";

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Partnering with us</h2>
        </div>
        <div className="about-text">
             
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
           
            </div>
            <div  className="about-text">
             <h4 style={{textAlign: "center"}} >We offer to our partners:</h4>
             <p><span style={{fontWeight: "bold"}}>Market Access:</span> Our deep understanding of public procurement processes and established relationships with government agencies position us as a key player in the distribution of medical equipment.</p>
             <p><span style={{fontWeight: "bold"}}>Strategic Growth:</span> As we continue to expand, we are always looking for cutting-edge technologies and products that can set new benchmarks in healthcare delivery.</p>
             <p><span style={{fontWeight: "bold"}}>Tailored Solutions:</span> We work closely with our partners to adapt their offerings to meet the specific needs of our clients, ensuring that every product is not only top-quality but also highly relevant to the market.             </p>
            <p>At Care Medical Distribution, we believe that strong partnerships are the cornerstone of success. We invite forward-thinking manufacturers to join us in our mission to enhance healthcare through the distribution of innovative medical solutions. Together, we can make a lasting impact on the quality of patient care.</p>
          
           </div>
        {/* <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div> */}
      </div>
    </div>
  );
};
